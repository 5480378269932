import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=13ea17aa&scoped=true&"
import script from "./PageHeader.vue?vue&type=script&lang=js&"
export * from "./PageHeader.vue?vue&type=script&lang=js&"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=13ea17aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ea17aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {XLink: require('/home/node/frontend/components/XLink.vue').default,LoginPopup: require('/home/node/frontend/components/includes/loginPopup.vue').default,HeaderAutocomplete: require('/home/node/frontend/components/includes/HeaderAutocomplete.vue').default,PageHeaderCart: require('/home/node/frontend/components/includes/PageHeaderCart.vue').default,DigitalCard: require('/home/node/frontend/components/includes/DigitalCard.vue').default,MobileMenu: require('/home/node/frontend/components/includes/MobileMenu.vue').default,MegaMenu: require('/home/node/frontend/components/includes/MegaMenu.vue').default})
